@import '@fontsource/roboto/300.css';
@import '@fontsource/roboto/400.css';
@import '@fontsource/roboto/500.css';
@import '@fontsource/roboto/700.css';

.slider {
  color: #20b2aa;
}
.swal2-container {
    z-index: 100000!important;
}


.MyTextArea p {
    margin: 0;
    padding: 0;
}
/* [START]: Styles for /components/Loader.jsx ::::::::::::::::::::::::::: */
div.loading-ui-overlay {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background-color: RGBA(0, 0, 0, 0.5); 
    z-index: 100; 
}

div.loading-ui-overlay div.loading-ui-wrapper {
    position: relative;
    margin: 15% auto;
    max-width: 30rem;
}

div.loading-ui-overlay div.loading-ui-wrapper div.loading-ui-body {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border-radius: .25rem;
    text-align: center;
    padding: 1.25rem;
    border: none;
    -webkit-box-shadow: 0px 0px 13px 0px rgba(236, 236, 241, 0.44);
    box-shadow: 0px 0px 13px 0px rgba(236, 236, 241, 0.44);
    margin: 10px;
    color: #5b626b;
    padding: 1.25rem;
}
/* [END]: Styles for /components/Loader.jsx ::::::::::::::::::::::::::::: */
